





























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchCheckLoggedIn,
  dispatchSelectPlan,
  dispatchGetServiceBuilding,
  dispatchGetBillingAddress,
} from "@/store/main/actions";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import _ from "lodash";

@Component({
  components: {},
})
export default class FeasibilityWorkflow extends Vue {
  public building = 1;
  public async mounted() {
    const building = await dispatchGetServiceBuilding(this.$store);
    const progress = _.get(building, "data.data.building.osp_progress", "");
    if (progress) {
      this.building = progress;
    }
  }
  hasProgress() {
    return !!this.building;
  }
}
