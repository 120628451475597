

















































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import { dispatchCheckLoggedIn } from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import { readUserProfile } from "@/store/main/getters";
import Progress from "./Progress.vue";
import _ from "lodash";
import FeasibilityWorkflow from "./FeasibilityWorkflow.vue";

@Component({
  components: { Header, Footer, Progress, FeasibilityWorkflow },
})
export default class ContactReview extends Vue {
  public progress = "3";
  public async mounted() {
    await dispatchCheckLoggedIn(this.$store);
    if (
      !location.host.startsWith("op") &&
      !location.host.startsWith("dev") &&
      !location.host.startsWith("localhost")
    ) {
      this.$gtag.event("conversion", {
        send_to: "AW-368517415/RieCCLqlp8QCEKfC3K8B",
      });
    }
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
}
